<template>
  <div>
    <b-row v-if="!profileLoading">
      <b-col>
        <b-card>
          <b-card-text>
            <form @submit.prevent="saveProfile">
              <b-form-group
                label="Avatar"
              >
                <p><a href='https://gravatar.com/connect/?source=_signup' target="_blank">Click here</a> to customize your profile picture. Make sure to sign-up with the same email address as your Portstock account.</p>
              </b-form-group>

              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="profile.name"
                />
              </b-form-group>

              <b-form-group
                label="Company"
                label-for="company"
              >
                <b-form-input
                  id="company"
                  v-model="profile.acf.company"
                />
              </b-form-group>

              <b-form-group
                label="Camera"
                label-for="camera"
              >
                <b-form-input
                  id="camera"
                  v-model="profile.acf.camera"
                />
              </b-form-group>

              <b-form-group
                label="City"
                label-for="city"
              >
                <b-form-input
                  id="city"
                  v-model="profile.acf.city"
                />
              </b-form-group>

              <b-form-group
                label="Bio"
                label-for="bio"
              >
                <quill-editor v-model="profile.acf.bio" :options="{ modules: { toolbar: editorToolbar } }"></quill-editor>  
              </b-form-group>
              
              <b-button
                type="submit"
                variant="success"
              >
                Save
              </b-button>
            </form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center mb-3">
      <b-spinner variant="primary" label="Loading" v-show="profileLoading"></b-spinner>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { BCard, BCardText, BFormGroup, BFormInput, BDropdown, BDropdownItem, BSpinner, BRow, BCol, BFormTags, BButton, } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/api'

import '@/@core/scss/vue/libs/vue-good-table.scss'

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardText,
    BFormGroup, 
    BFormInput,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BRow,
    BCol,
    BFormTags,
    BButton,
    quillEditor,
  },

  data() {
    return {
      profile: {},
      profileLoading: true,
      editorToolbar: [
        ['bold', 'italic'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ],
      newTitle: "",
    }
  },

  mounted() {
    this.getProfile()
  },

  methods: {
    getProfile() {
      api.users.me().then(response => {
        this.profile = response.data
        this.profileLoading = false
      })
    },

    saveProfile() {
      this.profileLoading = true

      api.users.update(this.profile).then(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Profile updated`,
            icon: 'CheckIcon',
            variant: 'success',
            text: '',
          },
        })

        this.profileLoading = false
      }).catch(response => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error occured`,
            icon: 'XIcon',
            variant: 'danger',
            text: 'Please try again later or contact support if the problem remains',
          },
        })

        this.profileLoading = false
      })
    }
  }
}
</script>

<style>
  .image-preview {
    max-width: 80px;
  }
</style>
